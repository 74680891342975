@use "../../master.scss";

.modal {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-title {
    color: #6F7CED;
  }

  .title {
    font-size: 14px;
    color: #000;
    margin-bottom: 2px;
    display: block;
  }

  .field {
    border-radius: 5px;
    font-weight: 400;
    display: block;
  }

  input {
    font-weight: 400;
    padding: 6px 10px;
    border-radius: 6px;

    &:focus,
    &:hover {
      border: 1px solid master.$primary-color;
      box-shadow: none;
    }
  }

  .ant-input-password {
    border-radius: 6px;
    padding: 0px 10px 0 10px;
    box-shadow: none !important;

    input {
      border: none;
      box-shadow: none;
    }

    &:focus,
    &:hover {
      border: 1px solid master.$primary-color;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
    font-weight: 400;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: master.$primary-color;
    box-shadow: none !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: master.$primary-color;
    box-shadow: none !important;
  }

  .ant-form-item-explain-error {
    font-size: 11px;
  }

  .checkbox-container {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #d9d9d9;

      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 3px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &:hover input~.checkmark {
      border-color: #40a9ff;
    }

    input:checked~.checkmark {
      background-color: #40a9ff;
      border-color: #40a9ff;
    }

    input:checked~.checkmark:after {
      display: block;
    }
  }

  .errorMessage {
    color: red;
    margin-bottom: 5px;
    font-size: 11px;
    min-height: 15px;
  }

  .modal-footer {
    text-align: right;
    margin-top: 20px;

    button,
    label {
      display: inline-block;
      margin: 5px;
      background: #6F7CED;
      color: #fff;
      font-size: 13px;
      padding: 6px 25px;
      border-radius: 5px;
      border: 1px solid #6F7CED;
      cursor: pointer;
      transition: 0.4s all;

      &:hover {
        background: #227eb8;
        border: 1px solid #227eb8;
      }

      &.close {
        background: transparent;
        border: 1px solid #868686;
        color: #000;

        &:hover {
          border: 1px solid #000;
        }
      }
    }
  }
}

.ant-select-in-form-item {
  height: 40px;

  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 100% !important;
  }
}