@use "master";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

*,
input,
button {
  outline: none;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

html,
body {
  min-height: 95%;
  margin: 0;
  background-color: #f0f2f5 !important;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

iframe {
  pointer-events: none;
}

.ant-breadcrumb li {
  color: #000 !important;
}

.ant-breadcrumb a {
  color: inherit !important;
}

.loader-wrapper {
  height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

::-webkit-file-upload-button {
  cursor: pointer;
}
.PhoneInput {
  padding: 4px 11px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px !important;
  font-weight: 300 !important;
  min-height: 40px;
  input {
    background: none !important;
    border: none;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
  }
  input::placeholder {
    color: #d9d9d9;
  }
}
.ant-form-item-has-error .PhoneInput {
  border-color: #ff4d4f !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #e2e5ff !important;
}

.custom-otp-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-otp-input input {
  width: 100% !important;
  border: 1px solid rgba(28, 43, 72, 0.1) !important;
  background: transparent;
  border-radius: 12px;
  outline: none;
  height: 100% !important;
}

.custom-otp-input input:focus {
  border: 2px solid #6f7ced !important;
}

.otpCodeInput {
  width: 58px;
  height: 54px;
  border-radius: 10px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:focus {
    outline: "none";
  }
}

.resend-wrapper {
  margin-bottom: 15px;
}

.resend-wrapper span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #243d4c;
}

.resend-wrapper .resend-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #6f7ced;
  margin-right: 10px;
  cursor: pointer;
}
